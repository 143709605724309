import React from 'react'
import { Link } from 'gatsby'
import Prices from '../../data/prices.json'
import Button from '../shortcodes/Button'
import ServicePricesStyles from '../../styles/ServicePrices.module.sass'

/**
 * Display a Pricing Table of Services
 * 
 * @param {string} className
 */
const ServicePrices = props => {
  return (
    <ul className={ServicePricesStyles.list + ' ' + (props.className || 'not-hero')}>
      {Prices.prices.map(({service, price, type}) => {

        return (
          <li key={service} data-type={type}>
            <Link to='/contact/'>
              <strong dangerouslySetInnerHTML={{ __html: service }} />
              <em><span>From</span> &euro;{price}</em>
            </Link>
          </li>
        )
      })}
      <Button text="Make an Enquiry" />
    </ul>
  )
}

export default ServicePrices