import React, { useState } from 'react'
import ContactFormStyles from '../styles/ContactForm.module.sass'

/**
 * Display a functional contact form
 *
 * @param {string} className
 * @param {string} buttonClass
 * @param {boolean} formOnly Toggles top info section | Default: false
 */
const ContactForm = props => {

  const initialEmailSubjectString = 'WindowCleaner Enquiry'

  // const [uploadedImages, setUploadedImages] = useState()

  const [emailSubject, setEmailSubject] = useState(initialEmailSubjectString)

  // const addUploadedImages = (uploadedImageUrl) => {
  //   let _uploadedImages = uploadedImages

  //   if (!_uploadedImages) {
  //     _uploadedImages = ''
  //   }

  //   _uploadedImages += '\n' + uploadedImageUrl

  //   setUploadedImages(_uploadedImages)
  // }

  const handleSetEmailSubject = (customerName) => {
    if (customerName && customerName.trim().length) {
      setEmailSubject(initialEmailSubjectString + ' from ' + customerName)
    }
    else {
      setEmailSubject(initialEmailSubjectString)
    }
  }

  // const showWidget = () => {
  //   const uploadOptions = {
  //     cloud_name: "specified",
  //     upload_preset: "windowcleaner-ie",
  //   }

  //   const widget = (typeof window !== 'undefined') ? window.cloudinary.createUploadWidget(
  //     uploadOptions,
  //     (error, result) => {
  //       if (!error && result && result.event === "success") {
  //         // console.log(result)

  //         addUploadedImages(result.info.secure_url)
  //       }
  //     }
  //   ) : null

  //   widget.open()
  // }

  return (
    <div
      className={`form-container ${ContactFormStyles.form} ${props.className ||
        ''}`}
    >
      <div>
        {(props.formOnly !== true || props.formOnly !== 'true') && (
          <>
            <span className={`title ${ContactFormStyles.title}`}>
              Get a Quote
            </span>
            <p>
              Fill out the form below and we will get back to you within a day (Monday to Friday).
            </p>
          </>
        )}

        <form
          name="WindowCleaner Enquiry"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-honeypot="first-name"
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="WindowCleaner Enquiry" />
          <input type="hidden" name="subject" value={emailSubject} />
          {/* <input type="hidden" name="uploaded-images" value={uploadedImages} /> */}
          <div hidden>
            <label>
              Trap for non-humans: <input name="first-name" />
            </label>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="name" hidden>
                Name
              </label>
              <input
                className="input"
                type={'text'}
                name={'name'}
                id={'name'}
                required={true}
                placeholder={'Your Name'}
                onChange={e => handleSetEmailSubject(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="email" hidden>
                Email
              </label>
              <input
                className="input"
                type={'email'}
                name={'email'}
                id={'email'}
                required={true}
                placeholder={'Your E-Mail'}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="phone" hidden>
                Phone
              </label>
              <input
                className="input"
                type={'tel'}
                name={'phone'}
                id={'phone'}
                required={true}
                placeholder={'Phone Number'}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="message" hidden>
                Message
              </label>
              <textarea
                className="textarea"
                name={'message'}
                id={'message'}
                required={true}
                placeholder={'What do you need done? Please include your property size and address.'}
              />
            </div>
          </div>
          {/* <div className="field">
            <div className="control is-split">
              <label htmlFor="upload-photos">
                Photo(s) of the area
              </label>
              <span
                className={`button is-primary ${ContactFormStyles.uploadPhotos}`}
                onClick={showWidget}
                onKeyPress={showWidget}
                role="button"
                tabIndex={0}
              >
                {uploadedImages ? 'Add more' : 'Add photos'}
              </span>
            </div>
          </div> */}
          {/* {uploadedImages && <div className={`notification is-success is-light ${ContactFormStyles.uploadedImagesSuccess}`}>Image(s) successfully added.</div>} */}
          <div className="field">
            <button
              className={`button is-large is-fullwidth ${ContactFormStyles.submit} ${props.buttonClass ? props.buttonClass : 'is-link'}`}
              type="submit"
            >
              Send Enquiry
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
